<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h1 class="brand-text text-primary ml-1">
            Security Assessment
          </h1>
        </b-link>
        <b-card-text>
          <process phase="Assessment" />
        </b-card-text>
        <b-card-text class="mb-3 text-center">
          <b-link traget="_blank" href="https://calendly.com/saasment-experts/30min?month=2021-07" class="btn btn-outline-primary mr-1">Schedule Meeting</b-link> or
          <b-link v-if="gw_auth_link"
            class="btn btn-outline-primary ml-1"
            :href="gw_auth_link"
            >
            Run Automaticly with Google
          </b-link>
          <span v-else>loading...</span>
        </b-card-text>
        <b-card-title class="mb-0">
          What is Security Assessment?
        </b-card-title>
        <b-card-text class="mb-2">
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>

import {
  BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Process from './Process.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    Process,
  },
  data() {
    return {
      gw_auth_link: '',
    }
  },
  mounted() {
    useJwt.getGWLink({}).then(response => {
      this.gw_auth_link = response.data
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
